import React, { type ChangeEvent, type FC, useCallback, useEffect, useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router-dom'
import { Table, Pagination, Input, Button, Spin } from 'antd'
import { CheckCircleOutlined, CloseCircleOutlined, PlusCircleOutlined } from '@ant-design/icons'
import { useAppStore, type Store } from '../store'
import type { SorterResult } from 'antd/es/table/interface'
import { ActionsMenu } from './actions-menu'

const { Search } = Input

const showTotal = (total: number): string => `Total ${total} items`

export const StoresTable: FC = observer(() => {
  const root = useAppStore()
  const { stores } = root
  const navigate = useNavigate()

  const columns = useMemo(() => [
    { title: 'ID', dataIndex: 'id', key: 'id', sorter: true },
    { title: 'Name', dataIndex: 'name', key: 'name', sorter: true },
    { title: 'Subdomain', dataIndex: 'subdomain', key: 'subdomain', sorter: true },
    { title: 'System Name', dataIndex: 'systemName', key: 'systemName', sorter: true },
    {
      title: 'Created By',
      dataIndex: 'user',
      key: 'user',
      render: (value: any, row: Store) => {
        return row.user.name !== '' ? row.user.name : row.user.login
      }
    },
    {
      title: 'Last Edited',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (value: any, row: Store) => {
        return new Date(row.updatedAt).toLocaleString()
      }
    },
    {
      title: 'Date Created',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (value: any, row: Store) => {
        return new Date(row.createdAt).toLocaleString()
      }
    },
    {
      title: 'Active',
      dataIndex: 'active',
      key: 'active',
      render: (value: any, row: Store) => {
        return row.active ? <CheckCircleOutlined style={{ color: 'green', fontSize: 24 }} /> : <CloseCircleOutlined style={{ color: 'red', fontSize: 24 }} />
      }
    },
    {
      title: 'Running',
      key: 'running',
      render: (value: any, row: Store) => {
        return stores.statusMap[row.subdomain] ?? '-'
      }
    },
    {
      title: 'Actions',
      render: (value: any, row: Store) => <ActionsMenu store={row} />
    }
  ], [])

  useEffect(() => {
    stores.setFilter({})
  }, [stores])

  const handlePageChange = useCallback((page: number, pageSize: number) => {
    stores.setFilter({
      page,
      pageSize
    })
  }, [stores])

  const handleTableChange = useCallback((
    pagination: any,
    filters: any,
    sorter: SorterResult<Store> | Array<SorterResult<Store>>,
    extra: any
  ): void => {
    if (!Array.isArray(sorter)) {
      const field = (Array.isArray(sorter.field) ? sorter.field[0] : sorter.field) ?? ''
      stores.setFilter({ sort: `${sorter.order === 'descend' ? '-' : ''}${field}` })
    }
  }, [stores])

  const searchHandler = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    stores.setFilter({ q: e.target.value })
  }, [stores])

  return (
    <div>
      <Spin spinning={root.loading}>
        <Search
          placeholder="Search by name"
          onChange={searchHandler}
          style={{ width: 200, marginBottom: 16 }}
      />

        <Button
          icon={<PlusCircleOutlined />}
          type="primary"
          style={{ float: 'right' }}
          onClick={() => { navigate('new') }}
      >
          Add store
        </Button>
        <Table<Store>
          columns={columns}
          dataSource={stores.items}
          pagination={false}
          onChange={handleTableChange}
      />
        <Pagination
          current={stores.filter.page}
          pageSize={stores.filter.pageSize}
          total={stores.totalItems}
          showTotal={showTotal}
          showSizeChanger
          onChange={handlePageChange}
      />
      </Spin>
    </div>
  )
})
